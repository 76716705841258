import { Link } from "gatsby";
import React, { useContext, useEffect } from "react";
import { MixpanelContext, trackMixpanelOnScroll } from "../../tracking";
import UserDataContext from "../../userDataContext";

import "./subscribe.css";

export default function Component() {
    const mixpanel = useContext(MixpanelContext);
    const userData = useContext(UserDataContext);

    useEffect(() => {
        mixpanel.track("Visited landing page", {
            // path: window.location.href
            Path: window.location.pathname
        });
    }, []);

    useEffect(() => {
        trackMixpanelOnScroll(userData.data);
    }, []);

    return (
        <div className="subscribe">
            <div className="subscriptionPageHeader">
                <Link className="homePageLink" to="/">
                    <img
                        className="zeplinIcon"
                        srcSet="
                            /images/zeplin@3x.png 3x,
                            /images/zeplin@2x.png 2x,
                            /images/zeplin.png 1x"
                        src="/images/zeplin.png" />
                </Link>
            </div>
            <div className="subscriptionBody">
                <h2
                    className="title">
                    Subscribe to the Zeplin Gazette
                </h2>
                <span
                    className="label">
                    We usually send newsletters once in a month
                </span>
                <form
                    action="https://zeplin.us8.list-manage.com/subscribe/post?u=4a3ed67799b4cd2da57182bc9&amp;id=9093b23628"
                    method="post"
                    className="subscriptionForm"
                    target="_blank"
                    noValidate>
                    <input
                        type="email"
                        name="EMAIL"
                        className="email"
                        placeholder="Email"
                        required />
                    <input
                        type="submit"
                        value="Subscribe to the Zeplin Gazette"
                        name="subscribe"
                        className="submit" />
                </form>
            </div>
        </div>
    );
}
